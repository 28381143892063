*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  --colors-border: #efefef;
  --colors-border-strong: #afafaf;

  --colors-text-placeholder: #777777;
  --colors-text-disabled: #999999;

  --colors-data-red: #af0000;
  --colors-data-green: #00af00;
}

body {
  margin: 0;
  font-family:
    -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:
    source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
